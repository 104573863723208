import firebase from 'utils/auth/firebase';
import logger from 'utils/logger';
import ROUTES from 'utils/routes';
import { STRIPE_PAYMENT_FUNCTION_NAME } from 'constants/env';

export const addListenerForUserSubscriptions = async (updateUserSubscriptions) => {
  const firestore = firebase.firestore();
  const userId = await firebase?.auth()?.currentUser?.uid;

  try {
    firestore
      .collection('customers')
      .doc(userId)
      .collection('subscriptions')
      .where('status', 'in', ['trialing', 'active'])
      .onSnapshot(async (snapshot) => {
        if (snapshot.empty) {
          logger.log('AppHandlers', 'User subscriptions was loaded successfully. No trialing or active subscriptions.');
          updateUserSubscriptions([]);
          return;
        }

        // In this implementation we only expect one Subscription to exist
        const subscriptions = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const subscriptionData = doc.data();
            const subscriptionPrice = (await subscriptionData.price.get()).data();
            const subscriptionDetails = subscriptionPrice.description;

            return {
              ...subscriptionData,
              price: subscriptionPrice,
              description: subscriptionDetails,
            };
          }),
        );

        logger.log('AppHandlers', 'User subscriptions was loaded successfully.', subscriptions);
        updateUserSubscriptions(subscriptions);
      });
  } catch (error) {
    logger.error('AppHandlers', 'Unable to receive user subscriptions.', error);
  }
};

export const generateUserSubscriptionPortalLink = async (functionLocation) => {
  const functionRef = firebase.app().functions(functionLocation).httpsCallable(STRIPE_PAYMENT_FUNCTION_NAME);
  const { data } = await functionRef({ returnUrl: `${window.origin}${ROUTES.root}` });

  return data.url;
};
