/* eslint-disable import/no-cycle */
import { call, select, takeLatest } from 'redux-saga/effects';
import { createRoutine } from 'redux-saga-routines';
import { v4 } from 'uuid';
import api from 'utils/api';
import { BASE_API_URL } from 'api/constants';
import logger from 'utils/logger';
import { getPersistentData, SESSION_PERSIST_KEY } from 'utils/localstorage';
import { getFavoritesSaga } from '../favorites';
import { initRealmSaga } from '../realm';
import { getHistorySaga } from '../history';
import { getActiveTrackIndex, getQueue } from '../player';
import { getUserId } from '../auth';

export const callPingRoutine = createRoutine('CALL_PING');
export const initRealmDataRoutine = createRoutine('INIT_REALM_DATA');
export const updateUserSubscriptionRoutine = createRoutine('UPDATE_USER_SUBSCRIPTIONS');

export const initialState = Object.freeze({
  config: null,
  userSubscriptions: null,
});

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case updateUserSubscriptionRoutine.REQUEST:
      return {
        ...state,
        userSubscriptions: action.payload,
      };
    default:
      return { ...state };
  }
};

export const getConfig = ({ app: { config } }) => config;

export function* initRealmDataSaga() {
  try {
    // Init Realm if available
    const database = yield call(initRealmSaga);
    // Load Favorite/History items from localstorage/Realm DB
    yield call(getFavoritesSaga, { database });
    yield call(getHistorySaga, { database });
  } catch (error) {
    logger.error('initRealmDataSaga', 'Error.', error);
  }
}

export function* callPingSaga({ payload }) {
  const queue = yield select(getQueue);
  const userId = yield select(getUserId);
  const activeIndex = yield select(getActiveTrackIndex);
  const session = getPersistentData(SESSION_PERSIST_KEY);
  const { trackId, assetId } = queue[activeIndex] ?? {};

  try {
    const data = {
      uuid: v4(),
      platform: 'web',
      user_id: userId,
      track_id: trackId,
      asset_id: assetId,
      timestamp: new Date(),
      session_id: session.id,
      length: Math.floor(payload.length),
      position: Math.floor(payload.position),
    };

    yield call(async () => api.post(`${BASE_API_URL}/pings/`, data));
  } catch (error) {
    logger.error('callPingSaga', 'Error.', error);
  }
}

export function* watchAppSagas() {
  yield takeLatest(callPingRoutine.request, callPingSaga);
  yield takeLatest(initRealmDataRoutine.request, initRealmDataSaga);
}
