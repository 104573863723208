import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import useContentPermission from 'hook/use-content-permission';
import { addListenerForUserSubscriptions } from 'utils/firestore';

const AppHandlers = memo(({ userId, initRealmDataAction, updateUserSubscriptionAction }) => {
  const { hasSubscriptionFlow } = useContentPermission();

  useEffect(() => {
    if (userId) {
      // Init Realm for history/favorite assets
      initRealmDataAction();

      if (hasSubscriptionFlow) {
        // Listen user subscriptions
        addListenerForUserSubscriptions(updateUserSubscriptionAction);
      }
    }
  }, [userId]);

  return null;
});

AppHandlers.propTypes = {
  userId: PropTypes.string,
  initRealmDataAction: PropTypes.func.isRequired,
  updateUserSubscriptionAction: PropTypes.func.isRequired,
};

export default AppHandlers;
